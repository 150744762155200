import $ from 'jquery';
import './scss/style.scss';
import 'slick-carousel/slick/slick.css'
import slick from 'slick-carousel'
import AOS from 'aos';
import 'aos/dist/aos.css';

// Fade in fade out
$('[data-page="default"]').fadeIn(800);

let newLocation = null;

function newpage() {
	window.location = newLocation;
}

// Animation
AOS.init({
  duration: 1000,
  delay: 100,
  offset: 10,
  once: true,
});
AOS.refresh();

// Resize
function resize() {
  if ($(window).width() > 1000) {
    $( ".menu-projects a" ).hover(
      function() {
        const item = $(this).attr('item')
        $( `.bg-image[item=${item}], .bg-overlay, .bg-overlay-opacity, .title[item=${item}]` ).css('opacity', 1)
        $( `.title[item=${item}]` ).css('display', 'inline-block')
        $( `.title.name` ).hide()
      }, function() {
        $( `.bg-image, .bg-overlay, .bg-overlay-opacity` ).css('opacity', 0)
        $( `.title` ).hide()
        $( `.title.name` ).css('display', 'inline-block')
      }
    );
  }
}
resize();
$(window).on('resize', resize); 

// Projects show and hide
const show = Number( $('.menu-projects').attr('show') )
const count = Number($('.menu-projects a').length) - show

$('.menu-projects a').each(function(idx, el){
  if(idx > show - 1) {
    $(this).addClass('hidden')
  }
})

$('.hide-and-show').click(function () {
  const more = $(this).hasClass('load-more')
  const less = $(this).hasClass('load-less')
  const elShow = $('.menu-projects a.hidden')
  const elHide = $('.menu-projects a.show')

  if( more ) {
    elShow.slice(0, count).addClass('show').removeClass('hidden');

    if ($('.menu-projects a').length == $('.menu-projects a:visible').length) {
      $(this).removeClass('load-more').addClass('load-less');
    }

  } else if( less ) {
    elHide.slice(0, count).addClass('hidden').removeClass('show');

    if ( $('.menu-projects a:hidden').length == count ) {
      $(this).removeClass('load-less').addClass('load-more');
    }
  }
});

// Darkmode
const currentTheme = localStorage.getItem("theme")

if (currentTheme == "dark") {
  $('html').removeClass('light-theme').addClass('dark-theme')
} else if (currentTheme == "light") {
  $('html').removeClass('dark-theme').addClass('light-theme')
}

$('.btn-toggle').click(function () {
  const has = $('html').hasClass('dark-theme')
  let theme;
  if(has){
    $(this).addClass('left')
    $('html').removeClass('dark-theme').addClass('light-theme')
    theme = "light";
  } else {
    $('html').removeClass('light-theme').addClass('dark-theme')
    $(this).removeClass('left')
    theme = "dark";  
  }
  localStorage.setItem("theme", theme);
});

// Fixo click carousel
var ceiling = $('.list .bg-image-fixo').length;
var floor = 1;
var current = 1;
var step = 1;

$('.bg-grid').click(function(){
  current += step;
  if (current === ceiling || current === floor) {
    step = -step;
  }
  $('.bg-image-fixo').css('opacity', 0)
  $(`.bg-image-fixo[data-index="${current}"]`).css('opacity', 1)
})

// Carousel
$('.carousel').each(function( idx, el ){
  const item = $(el).find('.carousel_slider')
  const time = Number( $(el).attr('data-time') )
  const loop = $(el).attr('data-loop')
  const transition = $(el).attr('data-transition')

  item.slick({
    // adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    cssEase: 'ease-in-out',
    arrows: loop != 'loop' ? true : false,
    autoplay: loop != 'loop' ? false : true,
    autoplaySpeed: time * 1000,
    dots: false,
    fade: transition != 'fade' ? false : true,
    nextArrow: '<button type="button" class="slick-next"><svg viewBox="0 0 21 18"><path d="M8.38272 1L1 9M1 9L8.38272 17M1 9H21"/></svg></button>',
    prevArrow: '<button type="button" class="slick-prev"><svg viewBox="0 0 21 18"><path d="M8.38272 1L1 9M1 9L8.38272 17M1 9H21"/></svg></button>'
  })
})

var lastScrollTop = 0;
$(window).scroll(function(event){
  var st = $(this).scrollTop();
  if (st > lastScrollTop){
    if(st >= 10) {
      $('.back').hide();
      $('.top').css({display: 'flex'});
    }
   } else {
    if(st <= 10) {
      $('.back').css({display: 'flex'});
      $('.top').hide();
    }
   }
   lastScrollTop = st;
});

$('.menu-about a, .menu-projects a, a.back').on('click', function(e) {
	e.preventDefault();
	newLocation = this.href;
	if(newLocation) {
		$('[data-page="default"]').fadeOut(800, newpage);
	} else {
		return;
	}
});

function showContent() {
  document.body.style.visibility = 'visible';
  document.body.style.opacity = 1;
}

window.addEventListener('DOMContentLoaded', function () {
  showContent();
});


// Move texture fixo
(function () {
  const bgTexture = $(".bg-texture");

  const standingSpeed = 0.5;
  const mouseMovingSpeed = 0.15;

  // direção inicial em graus da animação, antes de mover o mouse a primeira vez
  const startAngleInDegrees = 60;

  const mouse = {
    x: 0,
    y: 0,
    prevX: undefined,
    prevY: undefined,
    angle: startAngleInDegrees * (Math.PI / 180),
  };

  const standing = {
    enabled: true,
    velocityX: 0,
    velocityY: 0,
  };

  const backdrop = {
    x: 0,
    y: 0,
  };

  function draw() {
    window.requestAnimationFrame(draw);

    if (standing.enabled) {
      backdrop.x += standing.velocityX;
      backdrop.y += standing.velocityY;
    } else {
      standing.enabled = true;
    }

    bgTexture.css({
      backgroundPosition: `${backdrop.x}px ${backdrop.y}px`,
    });
  }

  function calculateMouseMovement() {
    standing.enabled = false;

    if (mouse.prevX === undefined) {
      mouse.prevX = mouse.x;
    }
    if (mouse.prevY === undefined) {
      mouse.prevY = mouse.y;
    }

    const diffX = mouse.x - mouse.prevX;
    const diffY = mouse.y - mouse.prevY;

    backdrop.x += diffX * mouseMovingSpeed;
    backdrop.y += diffY * mouseMovingSpeed;

    mouse.prevX = mouse.x;
    mouse.prevY = mouse.y;

    // se mover forte, muda a direção para o modo standing
    const mouseMoveIntensity = 20;
    if (
      Math.abs(diffX) > mouseMoveIntensity ||
      Math.abs(diffY) > mouseMoveIntensity
    ) {
      mouse.angle = Math.atan2(diffY, diffX);
      calculateStandingVelocity();
    }
  }

  function calculateStandingVelocity() {
    standing.velocityX = Math.cos(mouse.angle) * standingSpeed;
    standing.velocityY = Math.sin(mouse.angle) * standingSpeed;
  }

  function onMouseMove(e) {
    mouse.x = e.clientX;
    mouse.y = e.clientY;
    calculateMouseMovement();
  }

  function onTouchStart() {
    mouse.prevX = undefined;
    mouse.prevY = undefined;
  }

  function onTouchMove(e) {
    mouse.x = e.touches[0].clientX;
    mouse.y = e.touches[0].clientY;
    calculateMouseMovement();
  }

  window.addEventListener("mousemove", onMouseMove);

  window.addEventListener("touchstart", onTouchStart);
  window.addEventListener("touchmove", onTouchMove);

  calculateStandingVelocity();
  draw();
})();

// Marqueee
(function () {
  let marqueeEnabled = false;

  const title = $("h1 .title");
  const titleH1 = $("h1");

  // como esses valores mudam dependendo do marquee, temos q salvar eles em cache
  // atenção caso tenho problemas com o tempo de loading da fonte e do css
  const titleX = title.offset().left;
  const titleWidth = title.width();

  $(window).on("resize", onResize);
  onResize();

  function onResize() {
    if (window.innerWidth < titleX + titleWidth) {
      startMarquee();
    } else {
      endMarquee();
    }

    if (window.innerWidth > 1000) {
      $( ".menu-projects a" ).hover(
        function() {
          const attr = $(this).attr('item')
          const currentTitle = $(`h1 .title[item="${attr}"]`)
          const titlePadding = currentTitle.offset().left;
          const titleSize = currentTitle.width();
          if (window.innerWidth < titlePadding + titleSize) {
            startMarquee();
          }
        }, function() {
          endMarquee();
        }
      );
    }
  }

  function startMarquee() {
    if (marqueeEnabled) {
      return;
    }
    marqueeEnabled = true;

    title.addClass("marquee");
    titleH1.addClass("startMarquee");
    title.css({ animationDuration: title.width() * .005 + "s" });
  }

  function endMarquee() {
    if (!marqueeEnabled) {
      return;
    }
    marqueeEnabled = false;
    title.removeClass("marquee");
    titleH1.removeClass("startMarquee");
  }
})();